.signPad {
    width: 420px;
    max-width: 70vw;
    padding: 10px;
    border: 1px solid #ebebeb;
}

.signPad .inner-sign-pad {
    height: 230px;
    padding: 10px;
    border: 1px solid #ebebeb;
    height:500px;
}

.signPad .inner-sign-pad .canvas {
    height: 475px;
    width: 375px;
}

@media (max-width: 500px) {
    .signPad {
        max-width: 87vw;
        border: 1px solid #ebebeb;
    }

    .signPad .inner-sign-pad {
        padding: 0px;
        border: 1px solid #ebebeb;
        height:220px;
    }
    .signPad .inner-sign-pad .canvas {
        height: 100%;
        width: 100%;
    }
}

.fv_application_document-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 20px 5px 20px;
    background-color: #fafafa;
    border-radius: 8px;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;


    &+.fv_application_document-group {
        margin-top: 30px;
    }
}
